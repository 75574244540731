import React, { Component } from 'react';
import CommonAPI from '../../api/common';
import VideoDetail from './component/video';
import AtVideoDetail from './component/atVideo';
// import SmallVideoDetail from './component/smallVideo';
// import SmallVideoDetail from './component/smallVideoDetail';
import ArticleDetail from './component/article';
import util from '../../utils/util';
import urlUtil from '../../utils/Url';
import env from '../../utils/env';
import initWechatConfig from '../../utils/weShare';
import { OutLineResult } from './component/subComponents/outLineResult';
import './index.scss';

class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: '', // pl: 法规
      info: {},
      loading: true,
      guid: '',
      visibleOperate: false,
      isOutline: false, // 作品是否已经下线。 productStatus === 5
      outMsg: '该内容已下架',
      useFont: false,
    };
    this.methods = this.getMethods();
  }

  componentDidMount() {
    const { guid, source_page } = urlUtil.getUrlParams();
    const { match } = this.props;
    const { params } = match || {};
    const { id, key } = params || {};
    const appVersion = util.getAppVersion();
    const visibleVersion = env.isJKZGApp && util.versionStringCompare(appVersion, '2.0.1') === 1; // 大于2.0.0的版本展示
    const useFont = env.isJKZGApp && util.versionStringCompare(appVersion, '2.2.1') === 1; // 大于2.2.0的版本展示
    const visibleOperateInApp = visibleVersion && (source_page === 'channel' || source_page === 'topic'); // 专题或地方频道进入操作模块是否显示

    util.setXCXShare();
    this.setState({
      id,
      key,
      guid,
      readInfo: {},
      visibleOperate: env.isXCX || visibleOperateInApp || (!env.isXCX && !env.isJKZGApp && !env.isPc), // 小程序和大于2.0.0的app展示 以及非端内
      useFont,
    }, () => {
      this.methods.setArticleReadNum();
      this.methods.getDetail();
    });
  }

  getMethods() {
    return {
      getTest: () => {
        CommonAPI.getDevTest();
      },
      sendEvent: (eventName, info) => {
        window.collectEvent && window.collectEvent(eventName, {
          ...info,
          title: info.title || '',
          url: info.url || '',
          HealthNumber: info.healthNumberId || '',
          source: info.source || '',
        });
      },
      getDetail: async () => {
        const { key, guid } = this.state;
        try {
          let data;
          // 去缓存中查找数据 /public/index.html 提前获取详情数据
          if (window._cache_article_data && window._cache_article_data[guid] && !env.isSWANXCX) {
            data = await window._cache_article_data[guid];
          } else {
            data = await CommonAPI.getArticleDetail({
              id: guid,
              key,
            });
          }
          if (data) {
            const info = data;
            const { videoFiles } = info;
            document.title = info.title || '健康中国';
            if (Array.isArray(videoFiles) && videoFiles[0] && !videoFiles[0].posterUrl && info.posterUrl) {
              // 视频封面不存在时设置作品封面为视频封面
              videoFiles[0].posterUrl = info.posterUrl;
            }
            // console.log('info', info);
            if (info.type !== 2) {
              /* 图文资讯阅读量 */
              this.methods.sendEvent('show_DetailPage', info);
            }

            if (info.type === 2 && info.isRenderingHtml) { // 视频类数据，并且为抓取的数据
              const matchVideo = info.content.match(/<video.*?<\/video>/g);
              const videoStr = matchVideo ? matchVideo[0] : '';
              const matchVideoSrc = videoStr.match(/src="http.*?"/g);
              const videoSrc = matchVideoSrc ? matchVideoSrc[0] : '';
              if (videoSrc) {
                info.videoFiles = [{
                  url: videoSrc.split('"')[1],
                  title: '',
                  posterUrl: info.posterUrl || '',
                }];
                info.content = info.content.replace(/<video.*?<\/video>/g, '');
              } else {
                info.type = 1; // video链接不存在时，进入文章详情
              }
            }
            if (info.content && env.isXCX) {
              info.content = info.content.replace(/<iframe.*?<\/iframe>/g, '');
            }
            this.setState({
              info,
              loading: false,
              isOutline: info.status === 0,
            }, () => {
              initWechatConfig({
                title: info.title,
                imgUrl: 'https://image.jkzgxd.cn/tos-cn-i-a3h8qa8x9l/b3224baf2e3540cd964b888ec6d9ca55~tplv-a3h8qa8x9l-png.png?',
                desc: '打造每个人的健康中国',
              });
            });
          }
        } catch (error) {
          console.error(error);
          this.setState({
            isOutline: true,
            loading: false,
            outMsg: error.message || '内容未找到',
          });
        }
      },
      setArticleReadNum: async () => {
        const { id, key } = this.state;
        try {
          const data = await CommonAPI.Operation({
            id,
            source: key,
            operat: 'read',
          });
          this.setState({
            readInfo: data,
          });
        } catch (error) {
          console.error(error);
        }
      },
    };
  }

  render() {
    const {
      info, loading, readInfo, visibleOperate, isOutline, outMsg, useFont,
    } = this.state;
    const { match } = this.props;
    const { params } = match || {};
    const { key } = params || {};
    const isAd = key === 'ad';
    const searchs = urlUtil.getUrlParams(this?.props?.location?.search);
    const { biz_source } = searchs;
    // const isBlackList = info.id === 1199063 || info.id === 1199064 || info.id === 1199065;
    // const isBlack = !!(info.is_black) || isBlackList; // 点赞是否使用蜡烛的样式
    const isBlack = !!(info.is_black); // 点赞是否使用蜡烛的样式

    console.log('info', info);
    if (loading) {
      return (
        <div style={{
          color: '#999', fontSize: 16, marginLeft: 15, marginTop: 15,
        }}
        >
          加载中....
        </div>
      );
    }
    if (isOutline || (!info || !info.id)) {
      return (
        <OutLineResult msg={outMsg} />
      );
    }
    // 视频页面
    if (info.type === 2) {
      return isAd
        ? <VideoDetail bizSource={biz_source} useFont={useFont} info={info} source={key} visibleOperate={visibleOperate} /> // 活动视频页面
        : (
          <AtVideoDetail
            isBlack={isBlack}
            bizSource={biz_source}
            useFont={useFont}
            info={info}
            source={key}
            visibleOperate={visibleOperate}
          />
        ); // 普通资讯视频页面
    }
    // 普通图文 & 音频
    return <ArticleDetail isBlack={isBlack} bizSource={biz_source} useFont={useFont} className="article-page" source={key} info={info} readInfo={readInfo} visibleOperate={visibleOperate} />;
  }
}

export default Detail;
