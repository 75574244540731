import React from 'react';
import { Modal, Toast, Image } from 'antd-mobile';
// import commonApi from '../../api/common';
import ImageBackup from '../ImageBackup';
import env from '../../utils/env';
import './index.scss';
import urlUtil from '../../utils/Url';
/**
 * 微信小程序生成分享二维码
 * 组建内判断宿主环境，直接使用即可
 * - props.sharePath 需要生成二维码的地址
 */
export const ShareQr = (props) => {
  // const { qrCodeUrl, sharePath, onClick = () => { } } = props;
  const { qrCodeUrl = '', onClick = () => {} } = props;
  const { source_page } = urlUtil.getUrlParams();

  const handleShareClick = () => {
    /* 分享点击量 */
    window.collectEvent
      && window.collectEvent('detail_share_click', {
        source_page: source_page || '',
      });
    // `pages/webview/index?url=${encodeURIComponent(window.location.href)}`
    // const qrcodeImg = commonApi.getMiniCode(sharePath);
    Modal.show({
      title: '分享二维码',
      content: (
        <>
          <div style={{ textAlign: 'center' }}>
            <div
              style={{
                fontSize: '12px',
                lineHeight: '32px',
                color: '#999',
                margin: '0 auto',
              }}
            >
              长按下载二维码，分享至微信、朋友圈、QQ等
            </div>
            <Image
              placeholder={<ImageBackup />}
              fallback={
                <Image src="https://image.jkzgxd.cn/tos-cn-i-a3h8qa8x9l/a81634c5ec34548116d643a322910570.png~tplv-a3h8qa8x9l-5.png" />
              }
              onError={() => {
                Toast.show({
                  content: '当日生成次数已达到限制，请通过主页二维码分享进入',
                });
              }}
              src={qrCodeUrl}
              fit="cover"
              style={{ margin: '0 auto' }}
              width={200}
              height={200}
              alt=""
            />
          </div>
        </>
      ),
      bodyClassName: 'xcx-qr-body',
      closeOnAction: true,
      showCloseButton: true,
      actions: [
        {
          key: 'confirm',
          text: '确定',
        },
      ],
    });
    onClick && onClick(qrCodeUrl);
  };
  return (
    env.isWXXCX
    && (
      <div className="head-share" onClick={handleShareClick}>
        <div className="head-share-icon" />
        分享
      </div>
    ));
};
export default ShareQr;
