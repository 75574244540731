import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import util from '../../../../../utils/util';
// import { apiCommon } from '../../../API';
import CommonAPI from '../../../../../api/common';

import './index.scss';

const BottomDetail = (props) => {
  const { item } = props;

  console.log(item);

  const [isFab, setIsFab] = useState(item.isFab);

  const [fabCount, setFabCount] = useState(item.fabCount);

  const [isCollect, setIsCollect] = useState(item.isCollect);

  const [collectCount, setCollectCount] = useState(item.collectCount);

  useEffect(() => {}, []);

  async function clickCollection() {
    console.log('clickCollection');
    let operat;
    if (isCollect) {
      operat = 'cancel_collect';
    } else {
      operat = 'collect';
    }
    const res = await CommonAPI.Operation({
      operat,
      source: 'at',
      id: item.id,
    });
    console.log(res);
    if (res.code === 0) {
      setIsCollect(res.operat === 'collect');
      setCollectCount(res.count);
    } else if (res.code === 10008) {
      util.goAppLogin();
    }
  }

  async function clickLike() {
    console.log('clickLike');

    const res = await CommonAPI.Operation({
      operat: 'fab',
      source: 'at',
      id: item.id,
    });
    console.log('res', res);
    if (res.code === 0) {
      setIsFab(res.operat === 'fab');
      setFabCount(res.count);
    } else if (res.code === 10008) {
      console.log(10008);
      util.goAppLogin();
    }
  }

  return (
    <>
      <div className="smallVideoDetail-btm-content" onClick={() => {}}>
        <div className="btm-content-left">
          <img alt="logo" className="btm-content-logo" src={item.healthNumberAvatar || 'https://lf3-static.bytednsdoc.com/obj/eden-cn/fypbxup_lm_hyvybh/ljhwZthlaukjlkulzlp/health-china/healthChinaLogo.png'} />
          <div className="btm-content-item">
            <div className="btm-content-title">{item.authorName || '健康中国'}</div>
            <div className="btm-content-desc">{item.orgName || '国家卫生健康委员会'}</div>
          </div>
        </div>
        <div className="btm-content-right" style={{ display: 'none' }}>
          <div
            className="btm-content-collection"
            onClick={() => {
              clickCollection();
            }}
          >
            <div
              className={classnames(
                'btm-content-collection-logo',
                isCollect ? 'btm-content-collection-logo-active' : ''
              )}
            />
            <div className="btm-content-collection-num">
              {collectCount > 9999
                ? util.abbreviate(`${collectCount}`)
                : collectCount}
            </div>
          </div>
          <div
            className="btm-content-like"
            onClick={() => {
              clickLike();
            }}
          >
            <div
              className={classnames(
                'btm-content-like-logo',
                isFab ? 'btm-content-like-logo-active' : ''
              )}
            />
            <div className="btm-content-like-num">
              {fabCount > 9999
                ? util.abbreviate(`${fabCount}`)
                : fabCount}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BottomDetail;
