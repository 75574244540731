import React, { useState, useImperativeHandle, useEffect } from 'react';
import { Button } from 'antd-mobile';
import { ToAPPMask } from './component/toAPPMask';
import Conf from '../../conf/app';
import env from '../../utils/env';
import { isDYTT } from '../../utils/tool';
import './index.scss';
// import './indexPc.scss'; 暂无PC端展示场景

/**
 * 跳转APP
 * - props.detailPath 跳转的详情页地址
 */

export const ToAPPBtn = React.forwardRef((props, ref) => {
  const { detailPath = '' } = props;

  const [maskShow, setMaskShow] = useState(false);

  const toAPP = () => {
    if (env.isWeiXin) {
      setMaskShow(true);
    } else {
      // eg：'lvsongguo:/im?need-login&type=therapy_quick_ask'
      // android: jkzg:/DetailPage?id=d89a59d1f7be153e0e3d202d678a49dc&lptype=ad
      // ios: https:www.jkzg2030.cn/app-m/DetailPage?id=d89a59d1f7be153e0e3d202d678a49dc&lptype=ad
      console.log('跳转地址：', Conf.toAppScheme + detailPath);
      // window.location.href = Conf.toAppScheme + detailPath;
      if (env.isAndroid) {
        const iframe = document.createElement('iframe');
        iframe.setAttribute('src', Conf.toAppScheme + detailPath);
        iframe.setAttribute('style', 'display:none');
        document.body.appendChild(iframe);
        // 安卓跳转失败处理
        const _clickTime = +new Date();
        // 启动间隔20ms运行的定时器，并检测累计消耗时间是否超过3000ms，超过则结束
        let _count = 0;
        const intHandle = setInterval(() => {
          _count++;
          const elsTime = +new Date() - _clickTime;
          if (_count >= 100 || elsTime > 3000) {
            clearInterval(intHandle);
            document.body.removeChild(iframe);
            if (!(elsTime > 3000 || document.hidden || document.webkitHidden)) {
              window.location.href = Conf.appDownloadUrl;
            }
          }
        }, 20);
      } else if (env.isIOS) {
        window.location.href = Conf.toAppScheme + detailPath;
      }
    }
  };

  useEffect(() => {
    const appBtnDom = document.querySelector('.app-btn-wrap');
    if (!env.isJKZGApp && !env.isXCX && !env.isPc) {
      document.body.style.marginTop = `${appBtnDom.scrollHeight}px`;
    } else {
      document.body.style.marginTop = '0';
    }
  }, []);

  useImperativeHandle(ref, () => ({
    toAPP,
  }));

  return !isDYTT(navigator.userAgent) && !env.isJKZGApp && !env.isXCX && !env.isPc ? (
    <>
      {/* <div className="app-btn-wrap">
            <Button className="app-btn" onClick={toAPP} type="primary" size="small">打开健康中国（官方版）APP，参与投票</Button>
          </div> */}
      <div className="app-btn-wrap">
        <div className="app-btn-icon" />
        <div className="app-btn-text">
          <p className="app-btn-text-title">健康中国</p>
          <p className="app-btn-text-content">打造每个人的健康中国</p>
        </div>
        <Button className="app-btn-open" type="primary" onClick={toAPP}>
          打开
        </Button>
      </div>
      <ToAPPMask show={maskShow} setShow={setMaskShow} />
    </>
  ) : (
    <div className="app-btn-wrap hidden" />
  );
});
export default ToAPPBtn;
