import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';

import VideoInfo from './videoInfo';
import BottomDetail from './bottomDetail';
import getModule from '../../../../utils/asyncTransfer';
import { ToAPPBtn } from '../../../../component/toAPPBtn';
import util from '../../../../utils/util';

import './index.scss';

const SmallVideoDetail = (props) => {
  const info = props.info || {};
  const { videoFiles } = info || {};

  /* 播放器呀 */
  const videoContainRef = useRef();
  let videoPlayer = null;

  /* 初始化方法 */
  async function getInitData() {
    console.log('getInitData', info);
    getVideo();
  }

  function getVideo() {
    videoPlayer && videoPlayer.destroy();
    setTimeout(() => {
      if (Array.isArray(videoFiles)) {
        const videoItem = videoFiles[0] || {};
        getModule('xgplayer').then((mXgplayer) => {
          videoPlayer = new mXgplayer.default({ // eslint-disable-line
            el: videoContainRef.current,
            url: videoItem.url,
            poster: videoItem.posterUrl,
            width: '100%',
            height: '100%',
            // height: isXCX || isJKZGApp ? 'calc(100vh - 1.36rem)' : 'calc(100vh - 2.76rem',
            playsinline: true,
            // autoplay: true,
            lang: 'zh-cn',

            rotate: { // 视频旋转按钮配置项
              innerRotate: true, // 只旋转内部video
              clockwise: false, // 旋转方向是否为顺时针
            },
            loop: true,
            ignores: ['time', 'fullscreen', 'play', 'volume'],
          });
        });
      }
    }, 0);
  }

  useEffect(() => {
    getInitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className={classnames(
          'smallVideoDetail-video-detail-container'
        )}
      >
        <div className="video-content">
          <div
            className="video-player"
          >
            <div className="small-video" ref={videoContainRef} />
          </div>
          {/* 视频详情信息组件 */}
          <VideoInfo item={info} />
        </div>

        {/* 视频底部组件 */}
        <BottomDetail item={info} />

        {/* 引导下载app */}
        <ToAPPBtn detailPath={`DetailPage?id=${util.getQueryString('guid')}&lptype=${info.sourceFrom}`} />

      </div>
    </>
  );
};
export default SmallVideoDetail;
