import axios from 'axios';
import { Toast } from 'antd-mobile';
import Conf from '../conf/app';
import Token from './token';
import { initAxiosInterceptorsConfig } from './interceptors';
import env from '@/utils/env';

initAxiosInterceptorsConfig();
export class BaseRequest {
  baseURL = Conf.baseAPI;

  preFetch(config = {}) {
    return config;
  }

  setToken(config = {}) { // 设置token
    const token = Token.getToken();
    if (token) {
      config.headers[Token.reqKey] = token;
    }
    return config;
  }

  async fetch({
    url,
    method,
    params,
    closeErrorTip = false, // 是否关闭错误提醒
    headers,
    loading = true, // 是否开启loading效果
  }) {
    this.baseURL = Conf.setBaseUrl(url);
    method = method.toUpperCase();
    if (params && (method === 'GET' || method === 'DELETE')) {
      Object.keys(params).forEach((key) => {
        const item = params[key];
        if (item === null || item === undefined || item === '') {
          delete params[key];
        }
      });
    }
    if (loading) {
      Toast.show({
        icon: 'loading',
      });
    }
    const httpDefaultConfig = {
      method,
      baseURL: this.baseURL,
      url,
      params: method === 'GET' || method === 'DELETE' ? params : null,
      data: method === 'POST' || method === 'PUT' ? params : null,
      timeout: 60000,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
    };
    // 请求头注意区分 baseURL 不同的服务
    if (this.baseURL === 'https://api.jkzg2030.cn' || this.baseURL === 'https://api-test.jkzg2030.cn') {
      httpDefaultConfig.headers = {
        ...httpDefaultConfig.headers,
        'Biz-Source': env.bizSource, // 业务线
        'Utm-Source': env.utmSource, // 渠道
      };
    }
    const preConfig = this.preFetch(httpDefaultConfig); // 请求参数预处理
    const config = this.setToken(preConfig);
    const res = await axios(config).catch((error) => ({ error }));
    if (res.error) {
      if (!closeErrorTip) {
        Toast.show({
          icon: 'fail',
          content: '系统繁忙，请稍后再试',
        });
      }
      return Promise.reject(res.error);
    }
    if (res.status === 200 && res.data) {
      // 10008 为未登陆状态，不进行提示。直接返回进行跳转。
      if (res.data.code === 10008) {
        return Promise.reject(res.data);
      }
      if (res.data.code === 400000) {
        window.history.pushState(null, null, '/backup');
        window.location.href = `${window.location.origin}/backup`;
        return Promise.reject(res.data);
      }
      if (res.data.code === 200 || res.data.code === 0) {
        Toast.clear();
        return res.data.data;
      }
      if (!closeErrorTip) {
        Toast.show({
          icon: 'fail',
          content: res.data.message || '网络错误',
        });
      }
      return Promise.reject(res.data);
    }
    if (!closeErrorTip) {
      Toast.show({
        icon: 'fail',
        content: '网络错误',
      });
    }
    return Promise.reject(res.data);
  }
}

export default BaseRequest;
