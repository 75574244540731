const vConsoleModule = import('vconsole');
const xgplayerModule = import('xgplayer');
const browserClient = import('@apmplus/web');

const moduleCollect = {
  vconsole: vConsoleModule,
  xgplayer: xgplayerModule,
  apmPlus: browserClient,
};

export default async function (moduleName) {
  const _module = await moduleCollect[moduleName];
  return _module;
}
// export default vConsoleModule;
// console.log('vconsole', vconsole);
