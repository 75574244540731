import React from 'react';
import { SafeArea } from 'antd-mobile';
import Env from '@/utils/env';
import AppRouter from './router/index';
import ErrorBoundary from './component/ErrorBoundary';
import './App.scss';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {

  }

  render() {
    return (
      <div className="App">
        <ErrorBoundary>
          <AppRouter />
        </ErrorBoundary>
        {!!Env.isIOS && <SafeArea position="bottom" style={{ backgroundColor: '#f6f7fa' }} />}
      </div>
    );
  }
}

export default App;
