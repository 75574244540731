import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import { CacheSwitch } from 'react-router-cache-route';
import { QueryParamProvider } from 'use-query-params';
import Detail from '../page/detail/index';
import SVideo from '../page/detail/svideo';

// const Detail = lazy(() => import('../page/detail/index.jsx'));
const LiveDetail = lazy(() => import('../page/detail/live.jsx'));
const PreviewDetail = lazy(() => import('../page/detail/preview.jsx'));
const TopicAggregate = lazy(() => import('../page/topic/aggregate/index'));
const Topic = lazy(() => import('../page/topic/index'));
const TopicColumn = lazy(() => import('../page/topic/column/index'));
const Channel = lazy(() => import('../page/channel/index'));
// const Column = lazy(() => import('../page/topic/column/index'));
const ChannelColumn = lazy(() => import('../page/channel/column/index'));
const ChannelAggregate = lazy(() => import('../page/channel/aggregate/index'));
const ActivitiesDetail = lazy(() => import('../page/activities/detail'));
const Agreement = lazy(() => import('../page/agreement/index'));
const AgreementPrivacy = lazy(() => import('../page/agreementPrivacy/index'));
// const AgreementPrivacySimple = lazy(() => import('../page/agreementPrivacy/agreementPrivacySimple/index'));
const PersonDataList = lazy(() => import('../page/agreementPrivacy/personDataList/index'));
const APPPromiseList = lazy(() => import('../page/agreementPrivacy/appPromiseList/index'));
const EncyclopediaList = lazy(() => import('../page/encyclopedia/list/index'));
const EntryDetail = lazy(() => import('../page/encyclopedia/detail/index'));
const DownloadPage = lazy(() => import('../page/download/index'));
const HealthHospPosterPage = lazy(() => import('../page/healthHospPoster/index'));
const BrandUsageStatementPage = lazy(() => import('../page/brandUsageStatement/index'));
const AuthRankPage = lazy(() => import('../page/authRank/index'));
const JoinRankPage = lazy(() => import('../page/authRank/joinRankPage/index'));
const AuthRankRulePage = lazy(() => import('../page/authRank/rulePage/index'));
const TopicHenan = lazy(() => import('../page/topicHenan/index'));
const FeverClinics = lazy(() => import('../page/feverClinics/index'));
const KidsCare = lazy(() => import('../page/kidsCare/index'));
const HealthNumber = lazy(() => import('../page/healthNumber/index'));
const BackUp = lazy(() => import('../page/backUp/index'));
const AppFont = lazy(() => import('../page/other/index'));
const LogoutAgreement = lazy(() => import('../page/logout/index'));
const SDKCatalog = lazy(() => import('../page/sdkCatalog/index'));

const routerConfig = [{
  path: '/activities/detail/:id',
  component: ActivitiesDetail,
}, {
  path: '/detail/:id/:key', // 文章详情，不可随意改动。健康中国小程序中小程序码跳转有依赖该路由。
  component: Detail,
}, {
  path: '/preview/detail/:key', // 文章详情，不可随意改动。健康中国小程序中小程序码跳转有依赖该路由。
  component: PreviewDetail,
}, {
  path: '/svideo/:id/:key', // 文章详情，不可随意改动。健康中国小程序中小程序码跳转有依赖该路由。
  component: SVideo,
}, {
  path: '/live/:id', // 直播详情，不可随意改动。健康中国小程序中小程序码跳转有依赖该路由。
  component: LiveDetail,
}, {
  path: '/topic/aggregate',
  component: TopicAggregate,
}, {
  path: '/topic/page/:id',
  component: Topic,
}, {
  path: '/topic/column/:type/:id',
  component: TopicColumn,
}, {
  path: '/channel/aggregate',
  component: ChannelAggregate,
}, {
  path: '/channel/page/:id',
  component: Channel,
}, {
  path: '/channel/column/:type/:id',
  component: ChannelColumn,
}, {
  path: '/agreement',
  component: Agreement,
}, {
  path: '/agreementprivacy', // 隐私协议
  component: AgreementPrivacy,
},
// {
//   path: '/agreementprivacysimple', // 隐私协议简明版
//   component: AgreementPrivacySimple,
// },
{
  path: '/persondatalist', // 个人信息收集清单
  component: PersonDataList,
}, {
  path: '/agreementprivacy/apppromiselist', // app权限申请列表
  component: APPPromiseList,
}, {
  path: '/encyclopedia/list',
  component: EncyclopediaList,
}, {
  path: '/encyclopedia/detail/:id',
  component: EntryDetail,
}, {
  path: '/download',
  component: DownloadPage,
}, {
  path: '/healthposter',
  component: HealthHospPosterPage,
}, {
  path: '/brandusagestatement',
  component: BrandUsageStatementPage,
}, {
  path: '/authrank',
  component: AuthRankPage,
}, {
  path: '/authrank/join',
  component: JoinRankPage,
}, {
  path: '/authrank/rule',
  component: AuthRankRulePage,
}, {
  path: '/logout',
  component: LogoutAgreement,
}, {
  path: '/sdkcatalog',
  component: SDKCatalog,
}, {
  path: '/DetailPage',
  component: DownloadPage,
}, {
  path: '/henan',
  component: TopicHenan,
}, {
  path: '/fever',
  component: FeverClinics,
}, {
  path: '/kids',
  component: KidsCare,
}, {
  path: '/healthnumber',
  component: HealthNumber,
}, {
  path: '/backup',
  component: BackUp,
}, {
  path: '/appfont',
  component: AppFont,
}];

function AppRouter() {
  return (
    <Suspense
      fallback={(
        <div className="route-loader">
          {/* <Loader /> */}
        </div>
      )}
    >
      <QueryParamProvider ReactRouterRoute={Route}>
        <CacheSwitch>
          {
            routerConfig.map((routeItem) => [
              <Route key={routeItem.path} path={routeItem.path} exact component={routeItem.component} />,
              <Route
                key={`${routeItem.path}`}
                path={`${routeItem.path}`}
                exact
                component={routeItem.component}
              />,
            ])
          }
        </CacheSwitch>
      </QueryParamProvider>
    </Suspense>
  );
}

export default AppRouter;
