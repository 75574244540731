import axios from 'axios';
import { apmPlusReport } from './apm';

export const initAxiosInterceptorsConfig = () => {
  let startTime = 0;
  let requestUrl = '';

  const getUrlParams = (url = '') => {
    let result = {
      pathname: '',
      query: '',
    };
    try {
      const urlObj = new URL(url);
      result = {
        pathname: urlObj.pathname || '',
        query: urlObj.search || '',
      };
    } catch (error) {
      console.log(error);
    }
    return result;
  };

  axios.interceptors.request.use(
    (config) => {
      requestUrl = config.url || '';
      startTime = Date.now();
      const newConfig = {
        ...config,
        axStart: startTime,
        axStartHigh: 0,
      };
      try {
        newConfig.axStartHigh = window.performance.now();
        if (!window.axStore) {
          window.axStore = {};
        }
      } catch (e) {
        console.error(e);
        // do nothing
      }
      console.log('请求拦截');
      return newConfig;
    },
    (error) => {
      try {
        let urlParams = {
          pathname: '',
          query: '',
        };
        if (error?.request?.config?.url) {
          urlParams = getUrlParams(error?.request?.config?.url);
        }
        apmPlusReport({
          eventName: 'custom_request_error',
          params: {
            request_stage: 'request',
            request_path: urlParams?.pathname,
            request_query: urlParams?.query,
            request_url: error?.request?.config?.url,
            message: JSON.stringify(error),
            method: error?.request?.config?.method,
            request_time: `${error?.response?.config?.axStart}`,
            response_status: `${error?.response?.status}`,
          },
          metrics: {},
          level: 'fatal',
        });
      } catch (custom_request_error) {
        console.error('custom_request_error', custom_request_error);
      }
      return Promise.reject(error);
    }
  );

  // axios拦截器
  axios.interceptors.response.use((response) => {
    try {
      const {
        headers, data, request, config,
      } = response;
      // 避免打点过多正常请求消耗资源包
      if (data?.code !== 0 && data?.code !== 200) {
        const logid = headers?.['x-trace-id'] || '';
        apmPlusReport({
          eventName: 'custom_request_code',
          params: {
            request_stage: 'response',
            request_url: request?.responseURL,
            request_path: request?._url,
            method: config?.method,
            request_time: `${config?.axStart}`,
            response_status: `${request?.status}`,
            response_code: `${data?.code}`,
            logid,
          },
          metrics: {},
          level: 'fatal',
        });
      }
    } catch (error) {
      console.error('custom_request_code', error);
    }
    return response;
  }, (error) => {
    try {
      let urlParams = {
        pathname: '',
        query: '',
      };
      if (requestUrl) {
        urlParams = getUrlParams(requestUrl);
      }
      // 接口请求返回报错上报
      const logid = error?.response?.headers?.['x-trace-id'] || '';
      apmPlusReport({
        eventName: 'custom_request_error',
        params: {
          request_type:
            error === undefined || error?.code === 'ECONNABORTED'
              ? 'timeout'
              : 'error',
          request_stage: 'response',
          request_path: urlParams?.pathname,
          request_query: urlParams?.query,
          request_url: error?.response?.responseURL,
          method: error?.response?._method,
          request_time: `${error?.response?.config?.axStart}`,
          response_status: `${error?.response?.status}`,
          logid,
          message: error?.response?.data?.message || error?.message,
        },
        metrics: {},
        level: 'fatal',
      });
    } catch (custom_request_error) {
      console.error('custom_request_error', custom_request_error);
    }
    return Promise.reject(error);
  });
};

export default initAxiosInterceptorsConfig;
