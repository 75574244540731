import Request from '../utils/requestAxios';

class ShareApi extends Request {
  getTicket(url) {
    return this.fetch({
      url: `/ccard/wx_ticket?url=${url}`,
      method: 'GET',
      closeErrorTip: true,
      loading: false,
    });
  }
}

export default new ShareApi();
