import Request from '../utils/requestAxios';
import Conf from '../conf/app';

class CommonAPI extends Request {
  // mobile/appInit
  /**
   * @article_id 初始化
   */
  appInit(params) {
    return this.fetch({
      url: '/mobile/appInit',
      method: 'GET',
      params,
    });
  }

  /**
   * @article_id 文章详情
   */
  getArticleDetail(params) {
    return this.fetch({
      url: '/mobile/article/detail',
      method: 'GET',
      params,
      headers: {
        'Utm-Source': Conf.getUtmSource(), // 用于百度小程序过审，判断百度小程序内过滤二维码
        'App-Version': Conf.version, // 目前仅用于百度小程序过审，判断百度小程序内过滤二维码
      },
    });
  }

  /**
   * @article_id 文章预览详情
   */
  getArticlePreviewDetail(params, headerConfig) {
    return this.fetch({
      url: '/web/v1/preview/info',
      method: 'GET',
      params,
      headers: headerConfig,
    });
  }

  /** 直播详情
   * @params.id 直播id
   */
  getLiveDetail(params) {
    return this.fetch({
      url: '/mobile/live/detail',
      method: 'GET',
      params,
      loading: false,
      closeErrorTip: true,
    });
  }

  /** 设置文章阅读量
   * @params.id 文章id
   * @params.source at: 文章咨询 ad: 活动作品 pl: 地方政策 tp: 专题文章
   * @params.operat read: 阅读
   */
  // setArticleRead(params) {
  //   return this.fetch({
  //     url: '/mobile/operation',
  //     method: 'POST',
  //     params,
  //   });
  // }
  Operation(params) {
    return this.fetch({
      url: '/mobile/operation',
      method: 'POST',
      params,
      loading: false,
      // closeErrorTip: true,
    });
  }

  /** 获取活动详情
   * @params.id 文章详情
   */
  getActivitiesDetail(params) {
    return this.fetch({
      url: '/mobile/activity/detail',
      method: 'GET',
      params,
    });
  }

  /** 获取小程序码
   * @params.source 来源 暂时无用默认为0
   * @params.path 需要生成小程序码的路径
   */
  getMiniCode(path) {
    const baseURL = Conf.setBaseUrl('/mobile/qrCode');
    return `${baseURL}/mobile/qrCode?source=1&path=${path}`; // 服务端格式，该返回可当作图片地址。
  }

  /**
   * @description: 直播签到
   * @param {*} params
   * @return {*}
   */
  postMobileLiveSign(params) {
    return this.fetch({
      url: '/mobile/live/sign',
      method: 'POST',
      params,
    });
  }

  /**
   * @description: 直播签到
   * @param {*} params
   * @return {*}
   */
  getMobileLiveParticipantSearch(params) {
    return this.fetch({
      url: '/mobile/live/participant_search',
      method: 'GET',
      params,
      loading: false,
    });
  }

  /**
   * @description: 分享资讯（文章和视频），上报接口
   * @param {*} params
   * @return {*}
   */
  postShareArticle = (params) => this.fetch({
    url: '/mobile/article/share_report',
    method: 'GET',
    params,
  });

  /**
   * @description: 分享直播，上报接口
   * @param {*} params
   * @return {*}
   */
  postShareLiving = (params) => this.fetch({
    url: '/mobile/live/share_report',
    method: 'GET',
    params,
  });
}

export default new CommonAPI();
