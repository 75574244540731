import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import 'core-js/stable';
import getModule from './utils/asyncTransfer';
import { initApmPlus } from '@/utils/apm';
import App from './App';
import reportWebVitals from './reportWebVitals';
import util from './utils/util';
import env from './utils/env';
import Token from './utils/token';
import './index.css';

const { jkzg_token, source } = util.getUrlParams() || {};
if (source === 'xcx_app') {
  if (jkzg_token) {
    Token.setToken(jkzg_token);
  } else {
    Token.delToken();
  }
}

util.setPageFitView(750, 750); // 设置html的根字体

// 初始化apm plus
initApmPlus();

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
if (env.isDev || env.isTest) {
  getModule('vconsole').then((m) => {
    // 调试工具
    new m.default(); // eslint-disable-line
  });
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
