// 环境判断
const {
  location = {},
  navigator = {},
} = window;
const {
  hostname = '',
} = location || {};
const { userAgent } = navigator || {};
const devHost = ['localhost', 'xx.dev.boe.byted.org', '10.79.155.92']; // 开发环境域名
const testHost = ['m-test.jkzg2030.cn'];
const prodHost = ['m.jkzg2030.cn'];
const isWXXCX = (/micromessenger/i.test(userAgent) && /miniprogram/i.test(userAgent)) || window.__wxjs_environment === 'miniprogram';
const isSWANXCX = window.swan?.webView?.getEnv().smartprogram;
const isAndroid = userAgent.toLowerCase().indexOf('android') > -1 || userAgent.indexOf('Adr') > -1; // 判断是否是 android终端
const isOhos = userAgent.toLowerCase().indexOf('openharmony') > -1; // 判断是否是鸿蒙终端
const isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // 判断是否是 iOS终端
const isJKZGApp = userAgent.includes('health_china') || userAgent.includes('healthchina');
const isWeiXin = userAgent.toLowerCase().match(/MicroMessenger/i) && userAgent.toLowerCase().match(/MicroMessenger/i)[0] === 'micromessenger';
const isPc = function () {
  try {
    const term1 = /OpenHarmony|Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent);
    if (term1) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};
class Env {
    isProd = prodHost.includes(hostname);

    isTest = testHost.includes(hostname);

    isPreview = hostname === 'xx';

    isDev = devHost.includes(hostname);

    isWXXCX = isWXXCX;

    isSWANXCX = isSWANXCX; // 百度小程序

    isXCX = isWXXCX || isSWANXCX; // 微信小程序，百度小程序

    isJKZGApp = isJKZGApp; // 健康中国APP

    isAndroid = isAndroid; // 安卓

    isIOS = isIOS; // 苹果

    isOhos = isOhos // 鸿蒙

    isWeiXin = isWeiXin; // 微信

    bizSource = 'official'; // 业务线

    utmSource = 'h5'; // 渠道

    userAgent = userAgent; // UA

    // eslint-disable-next-line no-nested-ternary
    client = isJKZGApp ? 'app' : (isWXXCX ? 'mp' : 'h5');

    // eslint-disable-next-line no-nested-ternary
    env = this.isProd ? 'production' : (this.isTest ? 'test' : 'dev');

    isPc = isPc();
}

export default new Env();
