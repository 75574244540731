import React, { Component } from 'react';
import { Button } from 'antd-mobile';
import PropTypes from 'prop-types';
import Operate from '../subComponents/operate';
import './index.scss';
import './indexPc.scss';
import util from '../../../../utils/util';
import env from '../../../../utils/env';
import { ShareQr } from '../../../../component/shareQr';
import { ToAPPBtn } from '../../../../component/toAPPBtn';
import { SubTitle } from '../subComponents/subTitle/index';
import { Statement } from '../subComponents/statement/index';
// import { PV } from '../subComponents/pv/index';
import rewordTitleImg from '../img/reword_title.png';
import CommonAPI from '../../../../api/common';
import urlUtil from '../../../../utils/Url';

const propTypes = {
  info: PropTypes.object,
  // readInfo: PropTypes.object, // 阅读信息
  source: PropTypes.string,
  visibleOperate: PropTypes.bool, // 是否展示底部操作
};

const defaultProps = {
  info: {},
  // readInfo: {},
  source: '',
  visibleOperate: false,
};

class ArticleDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {},
    };
    this.toAPPBtnRef = React.createRef();
    this.methods = this.getMethods();
    this.domEvents = this.getDomEvents();
  }

  componentDidMount() {
    const { info } = this.props;
    this.setState({
      info,
    });
  }

  componentDidUpdate() {
    try {
      this.methods.initImg();
    } catch (error) {
      console.error('图片尺寸兼容错误', error);
    }
  }

  getMethods() {
    return {
      initImg: () => {
        const viewWidth = document.body.offsetWidth;
        const Imgs = document.querySelectorAll('#js_content img');
        const Box = document.querySelector('.detail-top');
        const padding = 0.32 * 100 * (document.body.offsetWidth / 750); // 文章内边距
        const jsComputedContentWidth = viewWidth - 2 * padding; // js计算属性 存在微小误差
        let domComputedContentWidth;
        try {
          domComputedContentWidth = parseFloat(window?.getComputedStyle(Box, null)?.width); // 页面计算属性宽度 0误差
        } catch (error) {
          console.log('获取页面宽度失败', error);
        }
        const maxWidth = domComputedContentWidth || jsComputedContentWidth; // 内容最大宽度
        for (let index = 0; index < Imgs.length; index++) {
          const img = Imgs[index];
          img.onload = () => {
            setTimeout(() => {
              if (img.naturalHeight && img.naturalWidth) {
                if (img.clientWidth > maxWidth) {
                  img.width = maxWidth;
                  img.height = maxWidth * (img.naturalHeight / img.naturalWidth);
                  img.style.width = '';
                  img.style.height = '';
                }
              }
            }, 50);
          };
        }
      },
      toAPP: () => {
        this.toAPPBtnRef?.current?.toAPP?.();
      },
    };
  }

  getDomEvents() {
    return {
      copyHandle: () => {
        const h5Link = util.removeUrlParams(['source', 'jkzg_token']);
        util.copy(h5Link);
      },
    };
  }

  render() {
    const { domEvents } = this;
    const {
      source, visibleOperate, useFont, isBlack,
    } = this.props;
    const { info } = this.state;
    const {
      isXCX, isIOS,
    } = env;
    const isAd = (source === 'ad'); // 活动
    const isPl = (source === 'pl'); // 政策法规
    const isNewSubTitleVersion = (!isAd && !isPl); // 副标题是否为卡片样式
    // const sharePath = window.location.pathname.replace('/app-m', '');
    if (!info || (!info.id && info.id !== 0)) {
      return null;
    }
    /**
     * 文章末尾显示附件
     */
    const { enclosures, tos_enclosures } = info;
    let showEnclosures = [];
    // 有新用新附件
    if (tos_enclosures && Array.isArray(tos_enclosures)) {
      showEnclosures = [...tos_enclosures];
    } else if (enclosures && Array.isArray(enclosures)) {
      showEnclosures = [...enclosures];
    }
    return (
      <div className={`${useFont ? 'han-font' : ''} ${visibleOperate ? `article-detail-card ${isIOS ? 'from-wx-ios' : 'from-wx'}` : 'article-detail-card'}`} id="only">
        <div className="detail-top">
          <div className="detail-title">{info.title}</div>
          <SubTitle toAPP={this.methods.toAPP} info={info} isAd={isAd} version={isNewSubTitleVersion ? 'new' : ''} detailPath={`DetailPage?id=${util.getQueryString('guid')}&lptype=${info.sourceFrom}`} />
          {
            info.rewardDetail && (
              <div className="reward-box fs-12">
                <div className="rew-com">
                  <img src={rewordTitleImg} alt="" />
                </div>
                <div className="rew-text">
                  {info.rewardDetail}
                </div>
              </div>
            )
          }
          {
            // 音频
            Array.isArray(info.videoFiles) && info.videoFiles.map((file, index) => (
              <>
                <div className="audio-box" key={file.url + index}>
                  {file.title && <p className="title">{file.title}</p>}
                  <audio
                    controls
                    src={file.url}
                  >
                    <track kind="captions" />
                  </audio>
                </div>
              </>
            ))
          }
          {
            // eslint-disable-next-line react/no-danger
            info.content && <div className="detail-content-wrap"><div ref={this.jsContent} className="rich-text-content detail-content" id="js_content" dangerouslySetInnerHTML={{ __html: info.content }} /></div>
          }
          {
            Array.isArray(showEnclosures) && showEnclosures.length > 0 && (
              <div className="lineItem">
                <div className="lTitle">附件：</div>
                <div className="lContent">
                  {
                    showEnclosures.map((item, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <React.Fragment key={item.url + index}>
                        <span className="linkTel" onClick={(e) => { util.toDownload(e, item.vid, item.name, item.url); }}>{item.name}</span>
                        <br />
                      </React.Fragment>
                    ))
                  }
                </div>
              </div>
            )
          }
          {
            isXCX && Array.isArray(showEnclosures) && showEnclosures.length > 0 && (
              <div className="fs-14 XCX-btn">
                <div className="downTip">注：请到健康中国APP中下载附件或复制作品链接去浏览器中下载</div>
                <Button onClick={domEvents.copyHandle} block color="primary" size="small">复制作品链接</Button>
              </div>
            )
          }
          {/* 资讯（'at'）隐藏阅读量 this.props.readInfo.count */}
          {/* 声明 */}
          <Statement bizSource={this.props.bizSource} />
          {/* 阅读量 */}
          {/* {isAd && <PV readCount={info.countStat.readCount} />} */}
        </div>
        {visibleOperate && <Operate isBlack={isBlack} toAPP={this.methods.toAPP} source={source} info={info} origin="info" />}
        {/* 仅传了path 和 query 因为参数过长会无法生成小程序码 在小程序端补齐host */}
        <ShareQr
          qrCodeUrl={info.qrCode}
          // sharePath={`pages/webview/index?url=${encodeURIComponent(`${sharePath}?guid=${util.getQueryString('guid')}`)}`}
          onClick={() => {
            // console.log('ShareQr-click', info);
            const { guid = '' } = urlUtil.getUrlParams();
            CommonAPI.postShareArticle({
              uid: guid,
            });
          }}
        />
        {/* ad代表活动中的作品 APP跳转仅在活动中的作品中显示 */}
        <ToAPPBtn ref={this.toAPPBtnRef} detailPath={`DetailPage?id=${util.getQueryString('guid')}&lptype=${info.sourceFrom}`} />
      </div>
    );
  }
}

ArticleDetail.propTypes = propTypes;
ArticleDetail.defaultProps = defaultProps;
export default ArticleDetail;
