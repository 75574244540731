import React from 'react';
import {
  Toast, ImageViewer, Button, Modal,
} from 'antd-mobile';
import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';
import { Url } from './Url';
import env from './env';
import Conf from '../conf/app';

const win = window;
const doc = document;

export const videoPreviewSuffix = ['mp4']; // 预览后缀名
export const imgPreviewSuffix = ['jpg', 'png', 'jpeg']; // 预览后缀名

class Util extends Url {
  // 文件下载
  downFile({
    path = '', // 接口路径
    query, // 请求查询参数
  }) {
    path = path || '/upload/file_download';
    const qString = this.toQueryString({
      ...query,
      // token: Token.getToken(),
    });
    const downHref = `${Conf.setBaseUrl(path)}${path}?${qString}`;
    this.downFileByAtag(downHref);
  }

  // 通过a标签下载文件
  downFileByAtag(href, name) {
    const a = document.createElement('a'); // 创建a标签
    a.setAttribute('download', name || '');// download属性
    a.setAttribute('href', href);// href链接
    // a.setAttribute('target', 'blank');// href链接
    a.click();// 自执行点击事件
  }

  // 通过a标签打开预览文件
  openFileByAtag(href) {
    const a = document.createElement('a'); // 创建a标签
    a.setAttribute('href', href);// href链接
    a.click();// 自执行点击事件
  }

  copyHandle() {
    const h5Link = this.removeUrlParams(['source', 'jkzg_token']);
    this.copy(h5Link);
  }

  toDownload(e, vid = '', name = '', url = '') {
    const suffix = name.split('.').pop();
    if (imgPreviewSuffix.includes(suffix)) {
      ImageViewer.show({
        image: url,
      });
      return;
    }
    if (env.isXCX || env.isWeiXin) {
      Modal.show({
        content: (
          <>
            <div className="fs-14 XCX-btn">
              <div className="downTip">注：请到健康中国APP中下载附件或复制作品链接去浏览器中下载</div>
              <Button onClick={() => { this.copyHandle(); }} block color="primary" size="small">复制作品链接</Button>
            </div>
          </>
        ),
        closeOnMaskClick: true,
      });
      return;
    }
    // 如果已经存在url 直接下载即可。
    if (url) {
      this.downFileByAtag(url, name);
      return;
    }
    e.preventDefault();
    const { data, key } = this.encrypt(vid, name);
    this.downFile({
      path: '/upload/file_download_by_vid',
      query: { data, key },
    });
  }

  /**
   * 设置UI宽度&最大宽度&监听页面尺寸变化
   * @param {number} designedWidth 设计稿设计宽度 375、750 默认 750
   * @param {number} maxWidth 内容区域最大宽度 750、1080 默认750
   */
  setPageFitView(designedWidth = 750, maxWidth = 750) {
    /**
     * 把设计图中的px转为对应数值缩小100倍的rem
     */
    const {
      body,
      documentElement: docEl,
    } = doc;
    const resize = 'orientationchange' in win ? 'orientationchange' : 'resize';

    const reCalc = () => {
      let {
        clientWidth,
      } = docEl;
      if (!clientWidth) {
        return;
      }
      if (clientWidth > maxWidth) {
        clientWidth = maxWidth;
      }
      const fontSizePx = clientWidth / designedWidth;
      // fontSizePx = fontSizePx > 1 ? 1 : fontSizePx;

      docEl.style.fontSize = `${100 * (fontSizePx)}px`;
      body.style.width = `${clientWidth}px`;
      body.style.margin = 'auto';
    };

    reCalc();
    win.removeEventListener(resize, reCalc, false); // 监听前清理
    win.addEventListener(resize, reCalc, false);
  }

  // 判断空对象
  isEmptyObj(obj) {
    return JSON.stringify(obj) === '{}';
  }

  setXCXShare() {
    const shareUrl = this.removeUrlParams(['source', 'jkzg_token']);
    if (env.isWXXCX) {
      try {
        window?.wx?.miniProgram?.postMessage?.({ data: { type: 'share', data: { url: shareUrl } } });
      // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  }

  copy(value, tip = '已成功复制至粘贴板') {
    // const scrollTop = window.pageYOffset;
    const transfer = document.createElement('input');
    transfer.readOnly = 'readOnly'; // 防止ios聚焦触发键盘事件
    transfer.style.position = 'absolute';
    transfer.style.left = '-1000px';
    transfer.style.zIndex = '-1000';
    document.body.appendChild(transfer);
    transfer.value = value; // 这里表示想要复制的内容
    transfer.focus();
    transfer.select();
    if (document.execCommand('copy')) {
      document.execCommand('copy');
      Toast.show({
        content: tip,
        icon: 'success',
      });
    } else {
      Toast.show({
        duration: 6000,
        content: `该系统不支持，请手动复制：${value}`,
      });
    }
    transfer.blur();
    document.body.removeChild(transfer);
    // window.scrollTo(0, scrollTop);
  }

  // 获取APP的版本
  getAppVersion() {
    const versionReg = /(\d+\.?)+/g;
    let version = '';
    if (!env.isJKZGApp) {
      return version;
    }
    if (env.isAndroid) {
      const reg = /health_china\/main\/(\d+\.?)+/g;
      const appInfoStr = window.navigator.userAgent.match(reg)[0];
      if (appInfoStr) {
        // eslint-disable-next-line prefer-destructuring
        version = appInfoStr.match(versionReg)[0];
      }
    }
    if (env.isIOS) {
      const reg = /Version\/(\d+\.?)+/g;
      const appInfoStr = window.navigator.userAgent.match(reg)[0];
      if (appInfoStr) {
        // eslint-disable-next-line prefer-destructuring
        version = appInfoStr.match(versionReg)[0];
      }
    }
    return version;
  }

  /**
   * 版本号判断
   * @param {*} preVersion 前一个版本
   * @param {*} lastVersion 后一个版本
   * @returns result -1: preVersion < lastVersion 0: preVersion === lastVersion 1: preVersion > lastVersion
   */
  versionStringCompare(preVersion = '', lastVersion = '') {
    const sources = preVersion.split('.');
    const dests = lastVersion.split('.');
    const maxL = Math.max(sources.length, dests.length);
    let result = 0;
    for (let i = 0; i < maxL; i++) {
      const preValue = sources.length > i ? sources[i] : 0;
      const preNum = isNaN(Number(preValue)) ? preValue.charCodeAt() : Number(preValue);
      const lastValue = dests.length > i ? dests[i] : 0;
      const lastNum = isNaN(Number(lastValue)) ? lastValue.charCodeAt() : Number(lastValue);
      if (preNum < lastNum) {
        result = -1;
        break;
      } else if (preNum > lastNum) {
        result = 1;
        break;
      }
    }
    return result;
  }

  /**
   * 客户端判断
   * @returns string
   */
  getClient() {
    let client = '';
    const userAgent = navigator.userAgent.toLowerCase();
    if (/(iphone|ipad|ipod|ios)/i.test(userAgent)) {
      client = 'ios';
    } else if (/(Android)/i.test(userAgent)) {
      client = 'Android';
      if (/(mi|mix|redmi)/i.test(userAgent)) {
        client = 'xiaomi';
      } else if (/(huawei|honor)/i.test(userAgent)) {
        client = 'huawei';
      } else if (/(oppo|pacm00)/i.test(userAgent)) {
        client = 'oppo';
      } else if (/(vivo)/i.test(userAgent)) {
        client = 'vivo';
      }
    } else {
      client = 'PC';
    }
    return client;
  }

  /**
   * FunctionDeclaration
   *
   * @param name - 需要获取的query字段名称(key)
   */
  getQueryString(name) {
    const QSreg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
    const r = window.location.search.substr(1).match(QSreg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
    return '';
  }

  encrypt(vid = '', name = '') {
    let key = this.randomString(16);
    const data = `vid=${vid}&file_name=${name}`;
    const encryptor = new JSEncrypt();
    const pubKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDkWpAkhRRHEI4pFb4FiDSq3AH7Hvif2Br5BE7MOsGLrQOI3lIes7c+VdAnA88ZNRBRiK2SWFjSrYgvgjvm/+5w4uhS471Sh8lJYN2fBp35q7l0KmN0mmuVt6DTZllx7QcOiReAetIFZDv7VXbfriFuh4Qdd7KccZuIatkw+pLhAQIDAQAB';
    const aesData = this.encryptAES(data, key);
    encryptor.setPublicKey(pubKey);
    key = encryptor.encrypt(key);
    return {
      data: aesData,
      key,
    };
  }

  // 加密方法
  encryptAES(word, key) {
    key = this.paddingLeft(key, 16); // 保证key的长度为16byte,进行'0'补位
    key = CryptoJS.enc.Utf8.parse(key);
    // 加密结果返回的是CipherParams object类型
    // key 和 iv 使用同一个值
    const encrypted = CryptoJS.AES.encrypt(word, key, {
      iv: key,
      mode: CryptoJS.mode.CBC, // CBC算法
      padding: CryptoJS.pad.Pkcs7, // 使用pkcs7 进行padding 后端需要注意
    });
    // ciphertext是密文,toString()内传编码格式,比如Base64,这里用了16进制
    // 如果密文要放在 url的参数中 建议进行 base64-url-encoding 和 hex encoding, 不建议使用base64 encoding
    return encrypted.ciphertext.toString(CryptoJS.enc.Hex); // 后端必须进行相反操作
  }

  paddingLeft(key, length) {
    let pkey = key.toString();
    const l = pkey.length;
    if (l < length) {
      pkey = new Array(length - l + 1).join('0') + pkey;
    } else if (l > length) {
      pkey = pkey.slice(length);
    }
    return pkey;
  }

  // 随机生成字符串
  randomString(e) {
    let n = '';
    if (typeof e !== 'number') {
      return n;
    }
    const t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz0123456789'.split('');
    for (let i = 0; i < e; i++) {
      n += t[Math.floor(Math.random() * t.length)];
    }
    return n;
  }

  /* 点赞、收藏数量转换xx.w */
  abbreviate(str) {
    if (+str > 9999) {
      const arr = str.slice(0, str.length - 3).split('');
      arr.push('w');
      arr.splice(arr.length - 2, 0, '.');
      const data = arr.join('');
      return data;
    }
    return str;
  }

  // 是否可以使用报社指定的宋体
  getUseSongFont() {
    const queryPreviewFont = this.getQueryString('fontPreview');
    if (queryPreviewFont == 1) {
      return true;
    }
    const appVersion = this.getAppVersion();
    const useFont = env.isJKZGApp && this.versionStringCompare(appVersion, '2.2.1') === 1; // 大于2.0.0的版本展示
    return useFont;
  }

  utf8ToB64(str) {
    if (unescape && escape) {
      return window.btoa(unescape(encodeURIComponent(str)));
    }
    return window.btoa(encodeURIComponent(str));
  }

  b64ToUtf8(str) {
    if (unescape && escape) {
      return decodeURIComponent(escape(window.atob(str)));
    }
    return decodeURIComponent(window.atob(str));
  }
}

export default new Util();
