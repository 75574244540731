import React, { useEffect, useRef, useState } from 'react';
import './index.scss';

/**
 * 图片加载时占位节点（& 失败节点）
 * */
function ImageBackup() {
  const ref = useRef(null);
  const [scale, setScale] = useState(1);
  useEffect(() => {
    if ((ref?.current)?.offsetWidth) {
      setScale((ref?.current)?.offsetWidth / 335);
    }
  }, []);
  const style = {
    transform: `scale(${scale})`,
  };
  return (
    <>
      {/* 界面中有较多图片，如果加在类名上会出现冲突 */}
      {/* <style>
        {`
          .image-backup-text {
            transform: scale(${scale});
          }
        `}
      </style> */}
      <div ref={ref} className="image-backup-wrap">
        <div className="image-backup-text" style={style}>
          健康中国
        </div>
      </div>
    </>
  );
}

export default ImageBackup;
