/*!
 * JavaScript url
 */
import Token from './token';
import env from './env';

const win = window;

export class Url {
  /**
     * ‘http’ or ‘//‘ 开头的返回true
     * @param {*} url
     */
  isAbsoluteUrl(url = '') {
    return url.indexOf('http') === 0 || url.indexOf('//') === 0;
  }

  /**
     * 解析url
     * @param {string} url 'x.com/haha?a=1&b=2#abc' [default location.href]
     * @returns {object} {
            hash: '#abc',
            base: 'x.com/haha',
            search: '?a=1&b=2',
            queryString: 'a=1&b=2',
        }
     */
  parseUrl(url = win.location.href) {
    const hashSplit = url.split('#');
    const hash = hashSplit[1];
    const querySplit = hashSplit[0].split('?');
    const base = querySplit[0];
    const search = querySplit[1];
    return {
      hash: hash ? `#${hash}` : '',
      base,
      search: search ? `?${search}` : '',
      queryString: search || '',
    };
  }

  /**
     * 对象转查询字符串
     * @param {object} obj {a: 1, b: 2}
     * @returns {string} 'a=1&b=2'
     */
  toQueryString(obj) {
    const s = [];
    if (obj) {
      Object.keys(obj).forEach((key) => {
        const value = this.isEmpty(obj[key]) ? '' : obj[key];
        s.push(`${key}=${encodeURIComponent(value)}`);
      });
    }
    return s.join('&');
  }

  isEmpty(vlaue) {
    return vlaue === null || vlaue === undefined || vlaue === '';
  }

  /**
     * 去除对象的空值key和key值的前后空格，key值为对象则作为json字符串
     *
     * 注意：obj instanceof FormData，不做处理
     * @param {object} obj {a: null, b: 2, c: '', d: [1,2], e: {a:1}} => {b: 2, d: '[1,2]', e: '{"a":1}'}
     * @param {boolean} isTrimValue 是否去除前后空格，默认true
     * @param {boolean} isDeleteEmptyKey 是否删除值为空的key，默认true
     */
  trimAndStringify(obj, isTrimValue = true, isDeleteEmptyKey = true) {
    if (obj && !(obj instanceof FormData)) {
      Object.keys(obj).forEach((key) => {
        let v = obj[key];
        v = this.isEmpty(v) ? '' : v;
        if (isDeleteEmptyKey && v === '') {
          delete obj[key]; // 过滤掉空参数
        } else if (typeof v === 'object') {
          obj[key] = JSON.stringify(v); // 对象转json字符串
        } else if (isTrimValue) {
          obj[key] = String(v).trim(); // 去除前后空格
        }
      });
    }
  }

  /**
     * 从url中获取参数名的值
     * @param {string} name 'a'
     * @param {string} url 'x.com?a=1&b=2#abc' [default location.href]
     * @returns {string} ‘1’
     */
  getUrlParam(name, url = win.location.href) {
    return this.getUrlParams(url)[name];
  }

  /**
     * 从url中获取参数对象
     * @param {string} url 'x.com?a=1&b=2#abc' [default location.href]
     * @returns {object} {a: 1, b: 2}
     */
  getUrlParams(url = win.location.href) {
    const params = {};
    const { queryString } = this.parseUrl(url);
    if (queryString) {
      const items = queryString.split('&');
      items.forEach((item) => {
        const kv = item.split('=');
        params[kv[0]] = kv[1] && decodeURIComponent(kv[1]);
      });
    }
    return params;
  }

  /**
     * 向url中添加参数对象
     * @param {object} params {c: 3}
     * @param {string} url 'x.com?a=1&b=2#abc' [default location.href]
     * @returns {string} 'x.com?a=1&b=2&c=3#abc'
     */
  addUrlParams(params = {}, url = win.location.href) {
    const qs = this.toQueryString(Object.assign(this.getUrlParams(url), params));
    const { base, hash } = this.parseUrl(url);
    return `${base}${qs ? `?${qs}` : ''}${hash}`;
  }

  /**
     * 从url中移除参数名数组对应的参数
     * @param {Array} keys ['a', 'c']
     * @param {string} url 'x.com?a=1&b=2&c=3#abc' [default location.href]
     * @returns {string} 'x.com?b=2#abc'
     */
  removeUrlParams(keys = [], url = win.location.href) {
    const params = this.getUrlParams(url);
    keys.forEach((k) => {
      delete params[k];
    });
    const { base, hash } = this.parseUrl(url);
    return this.addUrlParams(params, base) + hash;
  }

  /**
     * 替换当前页面url参数
     * @param {object} addParams 新增参数， delParams将要删除参数
     */
  replaceLocationHref({ addParams = {}, delKeys = [] }) {
    let newUrl = this.addUrlParams(addParams);
    if (Array.isArray(delKeys)) {
      newUrl = this.removeUrlParams(delKeys, newUrl);
    }
    window.history.replaceState(
      {
        ...window.history.state,
      },
      '', // Math.random()
      newUrl
    );
  }

  // 优先使用小程序webview打开页面
  goUrlXCXWebView(history, path) {
    const isLink = path && /^http(s)?:\/\//.test(path); // 是否为完整链接
    let url = '';
    if (isLink) {
      url = path;
    } else {
      url = `${location.origin}${path}`;
    }
    if (env.isXCX && !Token.getToken()) { // token不存在时，小程序webview打开，主要是用来刷新token
      if (env.isWXXCX) { // 微信
        window.wx && window.wx.miniProgram.navigateTo({ url: `/pages/webview/index?url=${encodeURIComponent(url)}` });
      }
      if (env.isSWANXCX) { // 百度
        window.swan && window.swan.webView.navigateTo({ url: `/pages/webview/index?url=${encodeURIComponent(url)}` });
      }
    } else if (isLink) {
      location.href = url;
    } else {
      history && history.push && history.push(path);
    }
  }

  // 唤起小程序、APP的登录
  goAppLogin() {
    if (env.isWXXCX) {
      window.wx && window.wx.miniProgram.navigateTo({ url: '/pages/login/index' });
    }
    // 百度小程序
    if (env.isSWANXCX) {
      window.swan && window.swan.webView.navigateTo({ url: '/pages/login/index' });
    }
    if (env.isJKZGApp) {
      location.href = 'jkzg:/LoginByPhoneNumberPage';
    }
  }
}

export default new Url();
