import React, { Component } from 'react';
import { Toast, Modal } from 'antd-mobile';
import collectIcon from '../../img/collect.png';
import collectedIcon from '../../img/collected.png';
import noticeIcon from '../../img/notice.png';
import niceIcon from '../../img/nice.svg';
import nicedIcon from '../../img/niced.svg';
import niceBlackIcon from '../../img/nice_gray.svg';
import nicedBlackIcon from '../../img/niced_gray.svg';
import closeImg from '../../img/close.png';
import voteImg from '../../img/vote.svg';
import votedImg from '../../img/voted.svg';
import CommonAPI from '../../../../../api/common';
import './index.scss';
import util from '../../../../../utils/util';
import urlUtil from '../../../../../utils/Url';
import env from '@/utils/env';
import { isDYTT } from '../../../../../utils/tool';

class Operate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFab: false, // 是否点赞
      isCollect: false, // 是否收藏
      fabCount: 0,
      collectCount: 0,
      showModal: false,
      showReportBlack: false,
      isNeedVote: true,
      isVote: false,
      voteCount: 0,
      visible: false,
    };
    this.methods = this.getMethods();
  }

  componentDidMount() {
    const { info } = this.props;

    const {
      isFab,
      isCollect,
      fabCount,
      collectCount,
      isNeedVote,
      isVote,
      voteCount,
    } = info || {};
    this.setState({
      fabCount,
      collectCount,
      isFab,
      isCollect,
      isNeedVote,
      isVote,
      voteCount,
    });
  }

  getMethods() {
    return {
      report: () => {
        this.setState({
          showModal: true,
        });
      },
      sendEvent: (eventName, info) => {
        const { source_page } = urlUtil.getUrlParams();
        window.collectEvent
          && window.collectEvent(eventName, {
            ...info,
            title: info.title || '',
            url: info.url || '',
            HealthNumber: info.healthNumberId || '',
            source: info.source || '',
            source_page: source_page || '',
          });
      },
      fab: () => {
        if (!env.isXCX && !env.isJKZGApp && !env.isPc) {
          return this.methods.beforeClickFn('点赞');
        }
        const { isBlack } = this.props;
        const { id, sourceFrom } = this.props.info;
        const info = this.props.info || '';

        this.methods.sendEvent('detail_like_click', info);

        if (this.state.isFab) {
          Toast.show(isBlack ? '重复操作 请明天再来' : '重复点赞 请明天再来');
        } else {
          CommonAPI.Operation({
            id,
            source: sourceFrom,
            operat: 'fab',
          })
            .then((data) => {
              this.setState(
                {
                  isFab: true,
                  fabCount: data.count,
                },
                () => {
                  Toast.show({
                    icon: 'success',
                    content: isBlack ? '操作成功' : '点赞成功',
                  });
                }
              );
            })
            .catch((e) => {
              if (e.code === 10008) {
                util.goAppLogin();
              }
            });
        }
      },
      vote: () => {
        if (!env.isXCX && !env.isJKZGApp && !env.isPc) {
          return this.methods.beforeClickFn('投票');
        }
        const { id, sourceFrom } = this.props.info;

        if (this.state.isVote) {
          Toast.show('重复投票 请明天再来');
        } else {
          CommonAPI.Operation({
            id,
            source: sourceFrom,
            operat: 'vote',
          })
            .then((data) => {
              this.setState(
                {
                  isVote: true,
                  voteCount: data.count,
                },
                () => {
                  Toast.show({
                    icon: 'success',
                    content: '投票成功',
                  });
                }
              );
            })
            .catch((e) => {
              if (e.code === 10008) {
                util.goAppLogin();
              }
            });
        }
      },

      collect: () => {
        if (!env.isXCX && !env.isJKZGApp && !env.isPc) {
          return this.methods.beforeClickFn('收藏');
        }
        const { isCollect } = this.state;
        const { id, sourceFrom } = this.props.info;
        const info = this.props.info || '';

        this.methods.sendEvent('detail_collect_click', info);

        CommonAPI.Operation({
          id,
          source: sourceFrom,
          operat: isCollect ? 'cancel_collect' : 'collect',
        })
          .then((data) => {
            if (data.operat === 'collect') {
              this.setState(
                {
                  isCollect: true,
                  collectCount: data.count,
                },
                () => {
                  Toast.show({
                    icon: 'success',
                    content: '收藏成功',
                  });
                }
              );
            } else {
              this.setState(
                {
                  isCollect: false,
                  collectCount: data.count,
                },
                () => {
                  Toast.show({
                    icon: 'success',
                    content: '取消收藏',
                  });
                }
              );
            }
          })
          .catch((e) => {
            if (e.code === 10008) {
              util.goAppLogin();
            }
          });
      },
      closeModal: () => {
        this.setState({
          showModal: false,
        });
      },
      block: () => {
        Toast.show({
          icon: 'success',
          content: '操作成功',
        });
        this.setState({
          showModal: false,
        });
      },
      beforeClickFn: (operateText) => {
        const { toAPP = null, info = {} } = this.props;
        if (!env.isXCX && !env.isJKZGApp && !env.isPc && toAPP) {
          // 端外操作弹框引导APP的展示量
          this.methods.sendEvent('detail_operate_to_app_modal_show', info);
          this.setState({
            modalMsg: `需要前往健康中国App登录才能${operateText || '操作'}`,
            visible: true,
          });
        }
      },
      modalOk: () => {
        const { toAPP = null, info = {} } = this.props;
        try {
          this.methods.sendEvent('detail_operate_to_app_modal_click', {
            ...info,
            operateType: 'operate_modal_to_app',
          });

          this.setState({
            visible: false,
          });
          toAPP();
        } catch (error) { }
      },
      modalCancel: () => {
        const { info = {} } = this.props;
        try {
          this.methods.sendEvent('detail_operate_to_app_modal_click', {
            ...info,
            operateType: 'operate_modal_cancel',
          });
          this.setState({
            visible: false,
          });
        } catch (error) { }
      },
    };
  }

  render() {
    const {
      showModal,
      showReportBlack,
      isFab,
      isCollect,
      fabCount,
      collectCount,
      isNeedVote,
      isVote,
      voteCount,
      visible,
      modalMsg,
    } = this.state;
    const { source, isBlack } = this.props;

    const TipContent = () => (
      <div className="tip-content-wrap">
        <div className="tip-content">{modalMsg}</div>
        <div className="tip-footer">
          <div className="tip-btn" onClick={this.methods.modalCancel}>取消</div>
          <div className="tip-btn" onClick={this.methods.modalOk}>立即跳转</div>
        </div>
      </div>
    );

    if (isDYTT(navigator.userAgent)) {
      return <></>;
    }

    return (
      <>
        <div className="content">
          <div className="operate-box" style={env.isIOS ? { paddingBottom: '40px' } : {}}>
            {showReportBlack && (
              <div
                className="operate-item"
                id="jubao"
                onClick={this.methods.report}
              >
                <img src={noticeIcon} alt="" />
                <span>举报</span>
              </div>
            )}
            {source === 'ad' ? (
              <>
                {isNeedVote && (
                  <div
                    className="operate-item column"
                    id="vote"
                    onClick={this.methods.vote}
                  >
                    <img src={isVote ? votedImg : voteImg} alt="" />
                    <span>
                      投票 &nbsp;
                      {voteCount || 0}
                    </span>
                  </div>
                )}
                <div
                  className="operate-item column"
                  id="fab"
                  onClick={this.methods.fab}
                >
                  {/* <img src={isFab ? nicedIcon : niceIcon} alt="" /> */}
                  { isBlack ? <img src={isFab ? nicedBlackIcon : niceBlackIcon} alt="" /> : <img src={isFab ? nicedIcon : niceIcon} alt="" />}
                  <span>
                    赞 &nbsp;
                    {fabCount || 0}
                  </span>
                </div>
              </>
            ) : (
              <>
                <div
                  className="operate-item"
                  id="collect"
                  onClick={this.methods.collect}
                >
                  <img src={isCollect ? collectedIcon : collectIcon} alt="" />
                  <span>{collectCount || 0}</span>
                </div>
                <div className="operate-item" id="fab" onClick={this.methods.fab}>
                  { isBlack ? <img src={isFab ? nicedBlackIcon : niceBlackIcon} alt="" /> : <img src={isFab ? nicedIcon : niceIcon} alt="" />}
                  {/* <img src={isFab ? nicedIcon : niceIcon} alt="" /> */}
                  <span>{fabCount || 0}</span>
                </div>
              </>
            )}
          </div>
          {/* 举报modal */}
          {showModal && (
            <div className="modal-box">
              <div className="modal-overlay" onClick={this.methods.closeModal} />
              <div className="modal-container">
                <div className="modal-header">
                  <img onClick={this.methods.closeModal} src={closeImg} alt="" />
                </div>
                <div className="modal-body">
                  <div className="modal-body-item" onClick={this.methods.block}>
                    拉黑
                  </div>
                  <div className="modal-body-item" onClick={this.methods.block}>
                    举报
                  </div>
                  <div className="modal-body-item" onClick={this.methods.block}>
                    不感兴趣
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal
          className="operate-rank-modal"
          visible={visible}
          // title="温馨提示"
          content={<TipContent />}
        />
      </>
    );
  }
}

export default Operate;
