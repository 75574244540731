import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { stamp2time } from '../../../../../utils/time';

import './index.scss';

const VideoInfo = (props) => {
  const { item } = props;

  /* 视频简介是否展开 */
  const [open, setOpen] = useState(false);

  const [showOpen, setShowOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const dom = document.querySelector('.video-title');
      if (dom.scrollHeight > dom.clientHeight) {
        setShowOpen(true);
      }
    }, 300);
  }, []);

  useEffect(() => {
    setOpen(false);
  }, [item]);

  /* 点击展开、收起 */
  function toggleTitle() {
    setOpen(!open);
  }
  return (
    <>
      <div
        className={classnames(
          'smallVideoDetail-info-box',
          item.title.length <= 62 ? 'info-box-normal' : ''
        )}
      >
        <div className="info-box-platform">
          <div className="video-title-warp">
            <div
              className={classnames('video-title', open ? 'video-title-5' : '')}
            >
              {showOpen && (
                <div
                  className="video-title-btn"
                  onClick={(e) => {
                    toggleTitle();
                    e.stopPropagation();
                  }}
                >
                  {open ? '收起' : '展开'}
                </div>
              )}

              {item.title}
            </div>
          </div>

          <div className="video-info">
            {stamp2time(item.publishTime, false)}
          </div>
        </div>

      </div>
    </>
  );
};
export default VideoInfo;
