import moment from 'moment';

export const stamp2time = (timestamp, needTime = true) => {
  if (String(timestamp)?.length < 13) {
    timestamp *= 1000;
  }
  if (needTime) {
    return `${moment(timestamp).format('YYYY-MM-DD HH:mm:ss')}`;
  }
  return `${moment(timestamp).format('YYYY-MM-DD')}`;
};
