import React from 'react';
import './index.scss';
import './indexPc.scss';

export const Statement = (props) => {
  const { bizSource } = props;
  let content = '声明：本文由入驻健康号作者撰写，观点仅代表作者本人，不代表健康中国官方版立场。';
  if (bizSource && bizSource === 'aiwei') {
    content = '声明：本文由入驻健康号作者撰写，观点仅代表作者本人，不代表全国爱国卫生运动办公室立场。';
  }
  return (<div className="info-statement">{content}</div>);
};

export default Statement;
