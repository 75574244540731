import React from 'react';
import { Mask } from 'antd-mobile';
import './index.scss';
import guidePath from './img/guide.png';

/**
 * 引导复制从浏览器打开的蒙层 可控
 * @param  show - 控制mask是否显示
 * @param  setShow - 设置show
 */

// eslint-disable-next-line arrow-body-style
export const ToAPPMask = ({ show = false, setShow = () => {} }) => {
  return (
    <Mask getContainer={() => document.querySelector('body')} style={{ '--z-index': 9999 }} className="guide-mask" visible={show} onMaskClick={() => setShow(false)}>
      <img className="guide-mask-img" src={guidePath} alt="" />
    </Mask>
  );
};
export default ToAPPMask;
