import env from '../utils/env';
import urlUtil from '../utils/Url';

class Conf {
  version = '2.1.0'; // 仅用于百度

  baseAPI = '';

  iosDownloadUrl = 'http://apps.apple.com/cn/app/id1238587231'; // IOS商店

  androidDownloadUrl = 'https://m.jkzg2030.cn/download?from=Android'; // H5下载页面

  appDownloadUrl = ''; // 根据环境取 iosDownloadUrl 或者 androidDownloadUrl

  androidScheme = 'jkzg:/'; // 跳转androidAPP的Scheme地址

  iosScheme = 'https://www.jkzg2030.cn/app-m/'; // 跳转iosAPP的Scheme地址  此处地址如果更改需要和端同学同步

  toAppScheme = 'jkzg:/'; // 跳转APP的Scheme地址

  constructor() {
    this.baseAPI = this.setBaseUrl();
    this.androidDownloadUrl = this.getAndroidDownloadUrl();
    this.appDownloadUrl = this.getAppDownloadUrl();
    this.toAppScheme = this.getAppScheme();
  }

  setBaseUrl(url = '') {
    // const protocolHttp = 'http://'; // 绕过代码检测工具
    const protocolHttps = 'https://';
    let baseAPI = ''; // 空字符串时为webpack-dev-server
    if (url.indexOf('/ccard/wx_ticket') !== -1 && !env.isDev) {
      return 'https://api.jkzg2030.cn/';
    }
    if (url.indexOf('/upload/file_download_by_vid') !== -1 && !env.isDev) {
      if (env.isProd) {
        return 'https://api.jkzg2030.cn';
      }
      return 'https://api-test.jkzg2030.cn';
    }
    // 专网api
    if (url.indexOf('/api/lotus') !== -1 && !env.isDev) {
      if (env.isTest) {
        return 'https://api-test.jkzgxd.cn';
      }
      return 'https://api.jkzgxd.cn';
    }

    switch (true) {
      case env.isDev:
        break;
      case env.isTest: // 目前后端测试环境只有一个，如果是多个需要映射不同的后端接口
        baseAPI = `${protocolHttps}api-test.jkzg2030.cn`;
        break;
      case env.isPreview:
        baseAPI = '';
        break;
      case env.isProd:
        baseAPI = `${protocolHttps}api.jkzg2030.cn`;
        break;
      default:
        baseAPI = '';
    }
    return baseAPI;
  }

  getAppDownloadUrl() {
    let url = ''; // 空字符串时页面刷新
    const way_from = urlUtil.getUrlParam('way_from');
    switch (true) {
      case env.isIOS:
        url = this.iosDownloadUrl;
        break;
      case env.isAndroid:
        url = this.androidDownloadUrl;
        if (way_from) {
          url = urlUtil.addUrlParams({ way_from }, url);
        }
        break;
      default:
        url = '';
    }
    return url;
  }

  // 获取安卓app下载链接
  getAndroidDownloadUrl() {
    const protocolHttp = 'http://'; // 绕过代码检测工具
    const protocolHttps = 'https://';
    let url = ''; // 空字符串时页面刷新
    switch (true) {
      case env.isDev:
        url = `${window.location.origin}/download?from=Android`;
        break;
      case env.isTest:
        url = `${protocolHttp}m-test.jkzg2030.cn/download?from=Android`;
        break;
      case env.isProd:
        url = `${protocolHttps}m.jkzg2030.cn/download?from=Android`;
        break;
      default:
        break;
    }
    return url;
  }

  // 获取App
  getAppScheme() {
    let url = ''; // 空字符串时页面刷新
    switch (true) {
      case env.isIOS:
        url = this.iosScheme;
        break;
      case env.isAndroid:
        url = this.androidScheme;
        break;
      default:
        url = '';
    }
    return url;
  }

  // 获取Utm-Source头信息
  getUtmSource() {
    let envType = 'other';
    switch (true) {
      case env.isWXXCX:
        envType = 'wechat_applets';
        break;
      case env.isSWANXCX:
        envType = 'baidu_applets';
        break;
      default:
        envType = 'other';
    }
    return envType;
  }
}

export default new Conf();
