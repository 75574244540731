import React from 'react';
import moment from 'moment';
import { Image } from 'antd-mobile';
import env from '@/utils/env';
import arrowRightIcon from '@/static/img/arrow_right.png';
import healthNumberHeadBack from '@/static/img/health_number_head.png';
import style from './index.module.scss';
import { isDYTT } from '@/utils/tool';

export const SubTitle = (props) => {
  const {
    isAd, info, version, toAPP,
  } = props;
  const tmPost = moment(info.publishTime).format('YYYY-MM-DD');
  const { isJKZGApp, isWXXCX, isPc } = env;
  const toHealthNumber = () => {
    if (isDYTT(navigator.userAgent) || isPc) {
      return;
    }
    const baseParams = {
      client: env.client,
      event_type: 'click',
      is_release: env.isProd, // 是否线上正式埋点的数据
    };
    window.collectEvent && window.collectEvent('article_detail_to_health_click', {
      ...baseParams,
      title: info.title,
      url: info.url,
      healthNumber: info.authorName,
      healthNumberId: info.healthNumberId,
      sourceFrom: info.sourceFrom,
      id: info.id,
      isAd,
    });
    if (isJKZGApp && info?.healthNumberId) {
      location.href = `jkzg:/HealthInfoPage?id=${info.healthNumberId}&name=${encodeURIComponent(info.authorName)}`;
    } else if (!isJKZGApp && !isWXXCX) {
      toAPP();
    }
  };
  if (version === 'new') {
    return (
      <div className={style.detailSourceWrap} onClick={toHealthNumber}>
        <div className={style.detailSourceCard}>
          <Image
            placeholder={<Image src={healthNumberHeadBack} />}
            fallback={<Image src={healthNumberHeadBack} />}
            className={style.detailSourceCardHead}
            src={info.healthNumberAvatar}
            fit="cover"
            alt=""
          />
          <div className={style.detailSourceCardInfo}>
            <div className={style.detailSourceCardInfoAuthor}>{info.authorName}</div>
            <div className={style.detailSourceCardInfoTime}>{tmPost}</div>
          </div>
          {!isDYTT(navigator.userAgent) && !isWXXCX && !isPc && (
            <div className={style.detailSourceCardArrow}>
              <img src={arrowRightIcon} alt="arrow" />
            </div>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className={style.detailSourceWrap}>
      <div className={`${style.detailSource} ${isAd ? style.ad : ''}`}>
        <div onClick={toHealthNumber}>
          {info.authorName}
        </div>
        <div className={style.detailSourceTime}>{tmPost}</div>
      </div>
    </div>
  );
};

export default SubTitle;
