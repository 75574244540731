import Cookie from 'js-cookie';
import env from './env';

class Token {
    saveKey = '_app_access_token_';

  reqKey = 'Access-Token'; // header中的key

  getToken() {
    let token = localStorage.getItem(this.saveKey);
    if (token) {
      return token;
    }
    if (env.isJKZGApp) { // 在APP中，从cookie中获取token
      token = Cookie.get('token');
    }
    return token;
  }

  setToken(token) {
    if (token) {
      localStorage.setItem(this.saveKey, token);
    }
  }

  delToken() {
    localStorage.removeItem(this.saveKey);
  }
}

export default new Token();
