/**
 * 普通资讯 视频类型的页面
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Player from 'xgplayer';
import { Button } from 'antd-mobile';
// import { UpOutline, DownOutline } from 'antd-mobile-icons';

import Operate from '../subComponents/operate';
import { ToAPPBtn } from '../../../../component/toAPPBtn';
// import { Sq, Zk } from '../../../../static/img/index.jsx';
import { ShareQr } from '../../../../component/shareQr';
import '../video/index.scss';
import './index.scss';
import './indexPc.scss';
import env from '../../../../utils/env';
import util from '../../../../utils/util';
import getModule from '../../../../utils/asyncTransfer';
import { Statement } from '../subComponents/statement/index';
import { SubTitle } from '../subComponents/subTitle/index';
import CommonAPI from '../../../../api/common';
import urlUtil from '../../../../utils/Url';

const propTypes = {
  info: PropTypes.object,
  source: PropTypes.string,
  visibleOperate: PropTypes.bool, // 是否展示底部操作
};

const defaultProps = {
  info: {},
  source: '',
  visibleOperate: false,
};
// 普通资讯视频页面
class AtVideoDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: {},
      currVideoIndex: 0,
      videoFiles: [],
      contentVisible: true,
    };

    this.toAPPBtnRef = React.createRef();
    this.videoContainRef = React.createRef();
    this.videoPlayer = null;
    this.domEvents = this.getDomEvents();
    this.methods = this.getMethods();
  }

  componentDidMount() {
    const { info } = this.props;
    const { videoFiles } = info || {};
    info.content = info.content || '';
    this.setState({
      info,
      videoFiles,
    }, this.methods.getVideo);
  }

  componentDidUpdate() {
    try {
      this.methods.initImg();
    } catch (error) {
      console.error('图片尺寸兼容错误', error);
    }
  }

  componentWillUnmount() {
    this.videoPlayer && this.videoPlayer.destroy();
  }

  getDomEvents() {
    return {
      copyHandle: () => {
        const h5Link = util.removeUrlParams(['source', 'jkzg_token']);
        util.copy(h5Link);
      },
      // handleContentVisible: () => {
      //   const { contentVisible } = this.state;
      //   this.setState({
      //     contentVisible: !contentVisible,
      //   });
      // },
    };
  }

  getMethods() {
    return {
      getVideo: () => {
        const { currVideoIndex, videoFiles } = this.state;
        this.videoPlayer && this.videoPlayer.destroy();
        const viewWidth = document.body.offsetWidth;
        const videoHeight = `${0.5625 * viewWidth}px`;
        setTimeout(() => {
          if (Array.isArray(videoFiles)) {
            const videoItem = videoFiles[currVideoIndex] || {};
            getModule('xgplayer').then((mXgplayer) => {
              this.videoPlayer = new mXgplayer.default({ // eslint-disable-line
                el: this.videoContainRef.current,
                url: videoItem.url,
                poster: videoItem.posterUrl,
                width: '100%',
                height: videoHeight,
                playsinline: true,
                lang: 'zh-cn',
              });
            });
          }
        }, 0);
      },
      initImg: () => {
        const viewWidth = document.body.offsetWidth;
        const Imgs = document.querySelectorAll('#js_content_video img');
        const Box = document.querySelector('.video-floor1');
        const padding = 0.32 * 100 * (document.body.offsetWidth / 750); // 文章内边距
        const jsComputedContentWidth = viewWidth - 2 * padding; // js计算属性
        let domComputedContentWidth;
        try {
          domComputedContentWidth = parseFloat(window?.getComputedStyle(Box, null)?.width); // 页面计算属性宽度 0误差
        } catch (error) {
          console.error('获取页面宽度失败', error);
        }
        const maxWidth = domComputedContentWidth || jsComputedContentWidth; // 内容最大宽度
        for (let index = 0; index < Imgs.length; index++) {
          const img = Imgs[index];
          img.onload = () => {
            setTimeout(() => {
              if (img.naturalHeight && img.naturalWidth) {
                if (img.clientWidth > maxWidth) {
                  img.width = maxWidth;
                  img.height = maxWidth * (img.naturalHeight / img.naturalWidth);
                  img.style.width = '';
                  img.style.height = '';
                }
              }
            }, 50);
          };
        }
      },
      toAPP: () => {
        this.toAPPBtnRef?.current?.toAPP?.();
      },
    };
  }

  render() {
    const {
      isXCX, isIOS,
    } = env;
    const { videoContainRef, domEvents } = this;
    const {
      source, visibleOperate, useFont, isBlack,
    } = this.props;
    const { info, contentVisible } = this.state;
    if (!info || (!info.id && info.id !== 0)) {
      return null;
    }
    /**
     * 文章末尾显示附件
     */
    const { enclosures, tos_enclosures } = info;
    let showEnclosures = [];
    // 有新用新附件
    if (tos_enclosures && Array.isArray(tos_enclosures)) {
      showEnclosures = [...tos_enclosures];
    } else if (enclosures && Array.isArray(enclosures)) {
      showEnclosures = [...enclosures];
    }
    const isAd = (source === 'ad');
    // const sharePath = window.location.pathname.replace('/app-m', '');
    return (
      <div className={`${useFont ? 'han-font' : ''} ${visibleOperate ? `at-video-container video-container ${isIOS ? 'from-wx-ios' : 'from-wx'}` : 'at-video-container video-container'}`} style={{ backgroundColor: '#fff' }}>
        {/* 视频模块 */}
        <div className="video" ref={videoContainRef} />
        {/* 标题 */}
        <div className="video-title">{info.title}</div>
        {/* 副标题为作者和简介操作 */}
        {/* <div className="video-author">
          <div>
            {info.authorName}
          </div>
          <div className="video-content-ctrl" onClick={domEvents.handleContentVisible}>
            简介
            {contentVisible ? <UpOutline /> : <DownOutline />}
          </div>
        </div> */}
        {/* 副标题为作者和日期 */}
        <div className="video-author">
          <SubTitle toAPP={this.methods.toAPP} info={info} isAd={isAd} version="new" detailPath={`DetailPage?id=${util.getQueryString('guid')}&lptype=${info.sourceFrom}`} />
        </div>
        <div className="video-floor1">
          {/* 详情内容 （除声明外） 可折叠 */}
          {
            contentVisible
            && (
              <div>
                {info.content && <div id="js_content_video" className="rich-text-content res-content" dangerouslySetInnerHTML={{ __html: info.content }} />}
                {
                  Array.isArray(showEnclosures) && showEnclosures.length > 0 && (
                    <>
                      <br />
                      <div className="lineItem">
                        <div className="lTitle">附件：</div>
                        <div className="lContent">
                          {
                            showEnclosures.map((item, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                              <React.Fragment key={item.url + index}>
                                <span className="linkTel" href={item.url} onClick={(e) => { util.toDownload(e, item.vid, item.name, item.url); }}>{item.name}</span>
                                <br />
                              </React.Fragment>
                            ))
                          }
                        </div>
                      </div>
                    </>
                  )
                }
                {
                  isXCX && Array.isArray(showEnclosures) && showEnclosures.length > 0 && (
                    <div className="fs-14 XCX-btn">
                      <div className="downTip">注：请到健康中国APP中下载附件或复制作品链接去浏览器中下载</div>
                      <Button onClick={domEvents.copyHandle} block color="primary" size="small">复制作品链接</Button>
                    </div>
                  )
                }
              </div>
            )
          }
          {/* 声明 */}
          <Statement bizSource={this.props.bizSource} />
        </div>
        {visibleOperate && <Operate isBlack={isBlack} toAPP={this.methods.toAPP} source={source} info={info} origin="video" />}
        {/* 仅传了path 和 query 因为参数过长导致无法生成小程序码 */}
        <ShareQr
          qrCodeUrl={info.qrCode}
          // sharePath={`pages/webview/index?url=${encodeURIComponent(`${sharePath}?guid=${util.getQueryString('guid')}`)}`}
          onClick={() => {
            /* 视频分享量 */
            const { guid = '' } = urlUtil.getUrlParams();
            CommonAPI.postShareArticle({
              uid: guid,
            });
          }}
        />
        <ToAPPBtn ref={this.toAPPBtnRef} detailPath={`DetailPage?id=${util.getQueryString('guid')}&lptype=${info.sourceFrom}`} />
      </div>
    );
  }
}

AtVideoDetail.propTypes = propTypes;
AtVideoDetail.defaultProps = defaultProps;

export default AtVideoDetail;
