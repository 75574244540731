import sharApi from '../api/share';
import urlUtil from './Url';

// 设置微信公众号分享
function initWechatConfig({ title = '', imgUrl = '', desc = '' }) {
  const url = window.location.href;
  const params = urlUtil.getUrlParams(url);
  const { debug = false } = params || {};
  const configUrl = encodeURIComponent(url);
  sharApi.getTicket(configUrl).then((resData) => {
    const config = {
      debug,
      appId: 'wx5749b20957c51680',
      timestamp: resData.timestamp,
      nonceStr: resData.nonceStr,
      signature: resData.signature,
      jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'],
    };
    if (!(window.wx && window.wx.config)) {
      return;
    }

    // 微信小程序
    if (navigator.userAgent.indexOf('miniprogram') > -1 || window.__wxjs_environment === 'miniprogram') {
      return;
    }
    window.wx.config(config);
    window.wx.ready(() => {
      console.log('ready');
      window.wx.updateAppMessageShareData({
        title, // 分享标题
        desc, // 分享描述
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl, // 分享图标
        success() {
          // 设置成功
          console.log('微信公众号分享设置成功');
        },
      });
      window.wx.updateTimelineShareData({
        title, // 分享标题
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl, // 分享图标
        success() {
          // 设置成功
        },
      });
    });
    window.wx.error((res) => {
      console.log('微信公众号二次分享初始化错误', res);
    });
  });
}

export default initWechatConfig;
