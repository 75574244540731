import React from 'react';
import { ErrorBlock } from 'antd-mobile';
import noContentImg from '../../img/no_content.png';
import './index.scss';

export const OutLineResult = ({ msg = '该内容已下架' }) => (
  <div className="outline-result">
    <ErrorBlock
      title={msg}
      image={noContentImg}
      description=""
    />
  </div>
);

export default OutLineResult;
