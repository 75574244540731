/**
 * 是否在抖音/抖极内
 */
const inDouYin = (ua) => /aweme/i.test(ua);

/**
 * 是否在头条/头极内
 */
const inTouTiao = (ua) => /newsarticle/i.test(ua);

// 判断宿主是否抖音或头条
const isDYTT = (ua) => inDouYin(ua) || inTouTiao(ua);

export { inDouYin, inTouTiao, isDYTT };
